export const getCentreImage = (
    metadata: string,
    categoryId?: string,
): string | undefined => {
    type CentreMetadata = {
        centreImages?: { url: string; tags: string[] }[];
        centreImageURLs?: string[];
    };
    const m: CentreMetadata = JSON.parse(metadata);
    if (!m.centreImages) {
        return m.centreImageURLs?.[0];
    }
    const firstImage = m.centreImages[0]?.url;
    if (categoryId) {
        return (
            m.centreImages.find((i) => i.tags.includes(categoryId))?.url ||
            firstImage
        );
    }
    return firstImage;
};
